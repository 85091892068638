import { FC, useState, useEffect } from 'react';
import Popover from 'react-tiny-popover';
import Image from '../../../Common/Image';
import Wrapper from '../../../Common/wrapper';
import { ChatHeaderProps } from './props';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import RequestRefreshPage from '../../../RequestRefreshPage';
import ApiService from '../../../../services/api';
import { addNotification } from '../../../../helpers/notification';
import ChatEnableList from '../chatEnableList';
import { timeout } from 'helpers/timer';
import { isAuctioneer, isProvider } from '../../../../helpers/permissions';
import { useTranslation } from 'react-i18next';
import { getCompetentAuthorityName } from '../../../../helpers/auction-rules';
import ExportChatButton from './export-chat-button.header';

const ChatHeader: FC<ChatHeaderProps> = ({
    title,
    icon,
    expandDirection,
    onExpand,
    showMinimize,
    auctionNotice,
    emitNotifications,
    handleEmitNotifications,
    socketConnection,
    authUser,
    openSearchBox,
    searchBoxOpened,
    providerCanSendMessages,
}) => {
    const [modalOpened, setmodalOpened] = useState(false);
    const [sendChatRequested, setSendChatRequested] = useState(providerCanSendMessages);
    const { t } = useTranslation();

    const { providerAuctionCode } = authUser;
    const auctionNoticeId = auctionNotice.id;

    const requestSendMessage = async () => {
        await ApiService.requestSendChat({
            auctionNoticeId,
        });

        setSendChatRequested(true);
        // bloqueio fake para não spamar requests de mensagem
        timeout(() => setSendChatRequested(false), 20000);

        addNotification(
            t('term.success'),
            t('info.requested.to.auctioneer', {
                name: getCompetentAuthorityName(auctionNotice),
            }),
            'success',
            3000
        );
    };

    useEffect(() => {
        setSendChatRequested(providerCanSendMessages);
    }, [providerCanSendMessages]);

    const canRequestSendMessage =
        !sendChatRequested && !!providerAuctionCode && isProvider(authUser);

    return (
        <Wrapper
            color='#FFF'
            height='46px'
            padding='10px'
            flexBox
            alignItems='center'
            justifyContent='space-between'
            bgcolor='#EAEAEA'
        >
            <Wrapper alignItems='center' flexBox>
                <Wrapper margin='0 9px 0  0'>
                    <Image src={`../../assets/icons/${icon}.svg`} width='26px' height='26px' />
                </Wrapper>
                <Wrapper title={title} color='#777' fontSize='14px' fontWeight='700'>
                    {title}
                </Wrapper>
            </Wrapper>
            <Wrapper flexBox justifyContent='flex-end'>
                <Wrapper>
                    {isProvider(authUser) ? (
                        <Image
                            src='../../assets/icons/hand.svg'
                            style={{
                                cursor: canRequestSendMessage ? 'pointer' : 'default',
                                opacity: canRequestSendMessage ? 1 : 0.5,
                            }}
                            width='17px'
                            height='17px'
                            title={
                                canRequestSendMessage
                                    ? t('info.request.message.to.aucioneer', {
                                          name: getCompetentAuthorityName(auctionNotice),
                                      })
                                    : t('info.provider.message.threshold')
                            }
                            margin='0 15px 0 0'
                            onClick={() => canRequestSendMessage && requestSendMessage()}
                        />
                    ) : null}
                    {isAuctioneer(authUser) ? (
                        <ChatEnableList auctionNoticeId={auctionNoticeId} />
                    ) : null}
                    {searchBoxOpened ? (
                        <Image
                            src='../../assets/icons/close.svg'
                            style={{ cursor: 'pointer' }}
                            width='14px'
                            height='14px'
                            onClick={() => openSearchBox()}
                        />
                    ) : (
                        <Image
                            src='../../assets/icons/loupe.svg'
                            style={{ cursor: 'pointer' }}
                            width='17px'
                            height='17px'
                            onClick={() => openSearchBox()}
                        />
                    )}
                    <Popover
                        isOpen={modalOpened}
                        position={['bottom', 'right', 'left']}
                        onClickOutside={(e: any) => {
                            if (e?.path?.find((e: any) => e?.id === 'requestRefreshPage')) {
                                return;
                            }
                            setmodalOpened(false);
                        }}
                        content={() => (
                            <Wrapper bgcolor='#FFF' padding='10px 12px'>
                                <Wrapper padding='8px 0' flexBox>
                                    <Wrapper padding='0 8px 0 0'>
                                        <Checkbox
                                            onChange={(ev: CheckboxChangeEvent) =>
                                                handleEmitNotifications(ev)
                                            }
                                            checked={!!emitNotifications}
                                        />
                                    </Wrapper>
                                    <Wrapper fontSize='13px'>
                                        {t('info.chat.emit.notification')}
                                    </Wrapper>
                                </Wrapper>
                                {isAuctioneer(authUser) && (
                                    <Wrapper flexBox>
                                        <RequestRefreshPage
                                            auctionNoticeId={auctionNoticeId}
                                            socketConnection={socketConnection}
                                        />
                                        <Wrapper margin='0 0 0 8px' color='#555' fontSize='13px'>
                                            {t('info.update.connected.providers')}
                                        </Wrapper>
                                    </Wrapper>
                                )}
                                <ExportChatButton auctionNotice={auctionNotice} />
                            </Wrapper>
                        )}
                    >
                        <Image
                            src='../../assets/icons/settings.svg'
                            style={{ cursor: 'pointer' }}
                            width='17px'
                            title={t('info.settings')}
                            height='17px'
                            margin='0 0 0 15px'
                            onClick={() => setmodalOpened(!modalOpened)}
                        />
                    </Popover>
                </Wrapper>
                {showMinimize && (
                    <Wrapper margin='0 0 0 12px' onClick={onExpand} cursor='pointer'>
                        <Image
                            transform={expandDirection}
                            title={t('info.minimize')}
                            src='../../assets/icons/arrow.svg'
                            width='18px'
                            height='18px'
                        />
                    </Wrapper>
                )}
            </Wrapper>
        </Wrapper>
    );
};

export default ChatHeader;
