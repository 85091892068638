import { FC } from 'react';
import { AuctionLotsProps } from './props';
import Scroll from '../Common/Scroll';
import { AuctionNoticeLot } from '../../Interfaces/auctionNoticeLot';
import { orderBy } from 'lodash';
import AuctionLot from '../AuctionLot';
import BulkActions from './components/BulkActions';
import { isAuctioneer } from '../../helpers/permissions';

const AuctionLots: FC<AuctionLotsProps> = ({
    auctionNoticeLots,
    onSelectAuctionLot,
    auctionNoticeLotSelected,
    auctionNotice,
    socketConnection,
    authUser,
    serverTimestamp,
    onUpdateSelectedLot,
    removeLotFromView,
    fetchLotsAgain,
}) => {
    return (
        <Scroll
            style={{
                borderTop: '1px solid #D6D6D6',
                borderBottom: '1px solid #D6D6D6',
                overflowY: 'auto',
                height: '100%',
            }}
        >
            {isAuctioneer(authUser) ? (
                <BulkActions auctionNotice={auctionNotice} fetchLotsAgain={fetchLotsAgain} authUser={authUser} />
            ) : null}
            {orderBy(Object.values(auctionNoticeLots) ?? [], 'item', 'asc').map(
                (lot: AuctionNoticeLot) => (
                    <AuctionLot
                        key={`lot:${lot.id}`}
                        serverTimestamp={serverTimestamp}
                        auctionNotice={auctionNotice}
                        socketConnection={socketConnection}
                        selected={lot.id === auctionNoticeLotSelected.id}
                        onSelectAuctionLot={onSelectAuctionLot}
                        auctionLot={lot}
                        authUser={authUser}
                        judgMentCriterion={auctionNotice.judgmentCriterion}
                        onUpdateSelectedLot={onUpdateSelectedLot}
                        removeLotFromView={removeLotFromView}
                    />
                )
            )}
        </Scroll>
    );
};

export default AuctionLots;
