import { FC } from 'react';
import { LotListAreaHeaderProps } from './props';
import Wrapper from '../Common/wrapper';
import CardLotMode from '../CardLotMode';
import { Select } from 'antd';
import { LotsTab } from 'Components/LotListArea';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../helpers/mobile';
import FiltersModal from './components/filters-modal';
import ModalDeclines from '../ModalDeclines';
import { TypeAccess } from '../../Interfaces/user';
import { useLotsContext } from '../../pages/Initial/select-lots.context';
import CardOnlineProviders from '../CardOnlineProviders';
import ModalMultipleWinnerCover from '../ModalMultipleWinnerCover';
import { isAuctioneer, isProvider } from '../../helpers/permissions';
import ModalVerificationOfProposalCompliance from 'Components/ModalVerificationOfProposalCompliance';
import { enableVerificationOfProposalCompliance } from 'helpers/enable-verification-of-proposal-compliance.helper';
import { featureFlagEnabled } from 'helpers/can-access';

const LotListAreaHeader: FC<LotListAreaHeaderProps> = ({
    authUser,
    auctionNotice,
    handlePaginateTabChanged,
    socketConnection,
}) => {
    const { t } = useTranslation();
    const { lotStageFiltered } = useLotsContext();

    const enableDeclines =
        !auctionNotice?.auctionTypeRules?.generalSettings?.cadastro?.desativarOpcaoDeclinio &&
        authUser.typeAccess === TypeAccess.organization;
    const showModalVerificationOfProposalCompliance =
        enableVerificationOfProposalCompliance(auctionNotice?.auctionTypeRules) &&
        (isProvider(authUser) || isAuctioneer(authUser));

    return (
        <Wrapper borderRight='1px #d7d7d7 solid' padding='2px'>
            <Wrapper
                position='relative'
                padding='12px 0'
                flexBox
                justifyContent='space-evenly'
                alignItems='center'
            >
                <CardLotMode authUser={authUser} auctionNotice={auctionNotice} />
                {enableDeclines && (
                    <ModalDeclines
                        auctionId={auctionNotice.id}
                        socketConnection={socketConnection}
                    />
                )}
                {isProvider(authUser) ? (
                    <ModalMultipleWinnerCover
                        auction={auctionNotice}
                        socketConnection={socketConnection}
                    />
                ) : null}
                {showModalVerificationOfProposalCompliance && (
                    <ModalVerificationOfProposalCompliance
                        process={auctionNotice}
                        socketConnection={socketConnection}
                        authUser={authUser}
                    />
                )}
                {authUser.typeAccess === TypeAccess.organization && <CardOnlineProviders />}
            </Wrapper>
            <Wrapper padding='0 10px 0px 10px'>
                <Wrapper title={t('term.filters-by-stage-text')}>
                    {t('term.filters-by-stage')}
                </Wrapper>
            </Wrapper>
            <Wrapper
                display='flex'
                justifyContent={
                    authUser?.typeAccess === TypeAccess.organization ? 'space-between' : 'flex-end'
                }
                padding='0 10px 5px 10px'
            >
                {!isMobile() && (
                    <Select
                        placeholder={t('term.select-action')}
                        style={{ minWidth: 300 }}
                        value={lotStageFiltered}
                        onChange={(value) => handlePaginateTabChanged(value)}
                    >
                        <Select.Option key={LotsTab.all} value={LotsTab.all}>
                            {t('info.lots.filter.all')}
                        </Select.Option>
                        <Select.Option key={LotsTab.not_started} value={LotsTab.not_started}>
                            {t('info.lots.filter.not-started')}
                        </Select.Option>
                        <Select.Option key={LotsTab.started} value={LotsTab.started}>
                            {t('info.lots.filter.receiving-bids')}
                        </Select.Option>
                        {featureFlagEnabled('habilitarAvancoDeEtapaEmMassa', authUser) && (
                            <>
                                <Select.Option
                                    key={LotsTab.open_period_ended}
                                    value={LotsTab.open_period_ended}
                                >
                                    {t('info.lots.filter.open-finished')}
                                </Select.Option>
                                <Select.Option
                                    key={LotsTab.random_period_ended}
                                    value={LotsTab.random_period_ended}
                                >
                                    {t('info.lots.filter.random-time-finished')}
                                </Select.Option>
                                <Select.Option
                                    key={LotsTab.close_period_ended}
                                    value={LotsTab.close_period_ended}
                                >
                                    {t('info.helper.close-ended.title')}
                                </Select.Option>
                            </>
                        )}
                        <Select.Option key={LotsTab.negotiation} value={LotsTab.negotiation}>
                            {t('info.lots.filter.negotiation')}
                        </Select.Option>
                    </Select>
                )}
                {isMobile() && <FiltersModal onChange={handlePaginateTabChanged} />}
            </Wrapper>
        </Wrapper>
    );
};

export default LotListAreaHeader;
