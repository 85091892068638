import { AuctionNotice } from 'Interfaces/auctionNotice';
import { MessageItem } from 'Components/Chat/interface';
import downloadDocument from '../download-document';
import useGenerateDocument from './use-generate-document';

/**
 * @name useExportChat
 * @summary - This hook returns a function that exports the chat messages as a document.
 *
 * @description - This hook is used to export the chat messages as a document. It receives the chat messages and the auction notice.
 * After generating the document, it downloads it to the user's device.
 * The file name is "chat-{auctionNumber}.docx". So if the auction number is 123/2024, the file name will be "chat-123_2024.docx".
 *
 * @example
 * const exportChat = useExportChat({ messages, auctionNotice });
 * exportChat(); // Downloads the chat messages as a document.
 *
 * @author - &commat;nascjoao &lt;oi&commat;nasc.dev&gt;
 */
export default function useExportChat({
    messages,
    auctionNotice,
}: {
    messages: MessageItem[];
    auctionNotice: AuctionNotice;
}) {
    const generateDocument = useGenerateDocument({
        chatMessages: messages,
        auctionNotice,
    });

    function exportChat() {
        const doc = generateDocument();
        downloadDocument(doc, `chat-${auctionNotice.auctionNumber}`);
    }

    return exportChat;
}
