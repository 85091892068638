import { Document, Packer } from 'docx';

/**
 * @name downloadDocument
 * @summary - This function saves the document to the user's computer.
 *
 * @description - This function receives a document and a file name as parameters.
 * It converts the document to a blob and creates a URL for it. Then, it creates a link element
 * which will be clicked to download the document. Finally, it removes the link element and revokes the URL.
 *
 * @author - &commat;nascjoao &lt;oi&commat;nasc.dev&gt;
 */
export default async function downloadDocument(doc: Document, fileName: string) {
    const buffer = await Packer.toBlob(doc);
    const url = window.URL.createObjectURL(buffer);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${fileName}.docx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
}
