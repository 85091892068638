import { StageLot } from 'Interfaces/stageLot';
import { SelectLotMode, useLotsContext } from 'pages/Initial/select-lots.context';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { AuctionNoticeLot } from 'Interfaces/auctionNoticeLot';
import BulkActionCheckboxView from './bulk-action-checkbox-view';

interface BulkActionCheckboxProps {
    lot: AuctionNoticeLot;
    selected: boolean;
}

const BulkActionCheckbox: FC<BulkActionCheckboxProps> = ({ lot, selected }) => {
    const { t } = useTranslation();
    const { selectedLots, selectedMode, setSelectedLots } = useLotsContext();

    const getCanShowCheckboxSelect = () => {
        switch (selectedMode) {
            case SelectLotMode.start:
                return lot.stage === StageLot.unStarted;

            case SelectLotMode.cancel:
                return [
                    StageLot.convoked,
                    StageLot.awaiting_call_provider,
                    StageLot.finished,
                    StageLot.awaiting_rejudge,
                    StageLot.started,
                    StageLot.negotiation,
                    StageLot.random_close_period_ended,
                    StageLot.close_period,
                    StageLot.close_period_ended,
                    StageLot.second_close_period,
                    StageLot.second_close_period_ended,
                    StageLot.repeat_second_close_period,
                    StageLot.repeat_second_close_period_ended,
                    StageLot.paused,
                    StageLot.random_close_period,
                    StageLot.random_period_ended,
                    StageLot.random_period,
                    StageLot.timeEnded,
                ].includes(lot.stage);

            case SelectLotMode.advanceOpen:
                return StageLot.timeEnded === lot.stage;
            case SelectLotMode.advanceClosed:
                return StageLot.close_period_ended === lot.stage && !lot.tiebreakerRound;
            case SelectLotMode.advanceRandom:
                return [StageLot.random_close_period_ended, StageLot.random_period_ended].includes(
                    lot.stage
                );
            case SelectLotMode.finishNegotiation:
                return lot.stage === StageLot.negotiation;
            default:
                return false;
        }
    };

    const getTooltipTitle = () => {
        if (getCanShowCheckboxSelect()) return '';

        const helpText = {
            [StageLot.second_close_period_ended]: t('info.bulk-action.second-close-period-ended'),
            [StageLot.awaiting_repeat_close_period]: t(
                'info.bulk-action.second-close-period-ended'
            ),
            [StageLot.stopped]: t('info.bulk-action.stopped'),
            [StageLot.canceled]: t('info.bulk-action.canceled'),
            [StageLot.negotiation_finished]: t('info.bulk-action.negotiation-finished'),
            [StageLot.no_winner_finished]: t('info.bulk-action.no-winner-finished'),
            [StageLot.awaiting_define_collocations]: t(
                'info.bulk-action.awaiting-define-collocations'
            ),
            [StageLot.awaiting_define_collocations_ended]: t(
                'info.bulk-action.awaiting-define-collocations'
            ),
            [StageLot.verification_of_proposal_compliance]: t(
                'info.bulk-action.verification-of-proposal-compliance'
            ),
        };
        return helpText[lot.stage] || t('info.bulk-action.default-action');
    };

    const onSelectLotToAction = (event) => {
        const checked = event.target.checked;
        const selectedLotsSet = new Set(selectedLots);

        if (checked) {
            selectedLotsSet.add(lot.id);
        } else {
            selectedLotsSet.delete(lot.id);
        }

        const newSelectedLots = Array.from(selectedLotsSet);
        setSelectedLots(newSelectedLots);
    };

    return (
        <BulkActionCheckboxView
            tooltipTitle={getTooltipTitle()}
            lotId={lot.id}
            onSelectLotToAction={onSelectLotToAction}
            disabled={!getCanShowCheckboxSelect()}
            selected={selected}
        />
    );
};

export default BulkActionCheckbox;
