import Wrapper from 'Components/Common/wrapper';
import { MdDownload } from 'react-icons/md';
import { useContext } from 'react';
import { MessagesContext } from 'Components/Chat/contexts/messages-context.chat';
import { AuctionNotice } from 'Interfaces/auctionNotice';
import { useTranslation } from 'react-i18next';
import useExportChat from './hooks/use-export-chat';

/**
 * @name ExportChatButton
 * @summary - This component renders a button that exports the chat messages as a document.
 *
 * @description - This component is used to export the chat messages as a document. It receives the auction notice as a parameter.
 * When the button is clicked, it calls the exportChat function, which generates the document and downloads it to the user's device.
 *
 * @example
 * <ExportChatButton auctionNotice={auctionNotice} />
 *
 * @author - &commat;nascjoao &lt;oi&commat;nasc.dev&gt;
 */
export default function ExportChatButton({ auctionNotice }: { auctionNotice: AuctionNotice }) {
    const { messages } = useContext(MessagesContext);
    const { t } = useTranslation();
    const exportChat = useExportChat({ messages, auctionNotice });

    return (
        <button onClick={exportChat} style={{ background: 'none', border: 'none', padding: 0 }}>
            <Wrapper padding='8px 0' flexBox style={{ cursor: 'pointer' }}>
                <Wrapper padding='0 8px 0 0' style={{ fontSize: 0 }}>
                    <MdDownload color='#555' size={20} />
                </Wrapper>
                <Wrapper fontSize='13px'>{t('info.chat.download')}</Wrapper>
            </Wrapper>
        </button>
    );
}
