import React from 'react';
import { MessageItem } from '../interface';

type MessagesContextValue = {
    messages: MessageItem[];
};

type MessagesProviderProps = {
    children: React.ReactNode;
    messages: MessageItem[];
};

export const MessagesContext = React.createContext({} as MessagesContextValue);

export function MessagesProvider({ children, messages }: MessagesProviderProps) {
    return <MessagesContext.Provider value={{ messages }}>{children}</MessagesContext.Provider>;
}
