import { FC, useState } from 'react';
import { Button, Checkbox, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { addNotification } from '../../../../helpers/notification';
import { SelectLotMode, useLotsContext } from '../../../../pages/Initial/select-lots.context';
import Wrapper from '../../../Common/wrapper';
import ApiService from '../../../../services/api';
import { AuctionNotice } from '../../../../Interfaces/auctionNotice';
import MessagePopover from '../../../Common/MessagePopover';
import ConfirmationPopover from '../../../Common/ConfirmationPopover';
import { methodDisputeDoAction } from 'helpers/methodDispute';
import { featureFlagEnabled } from 'helpers/can-access';
import { User } from 'Interfaces/user';
import { LotsTab } from 'Components/LotListArea';
import { noClosedBidAndHaveProviderCallable } from '../Auctioneer/ClosePeriodActions/check-if-cover-and-callable.helper';

interface BulkActionsProps {
    auctionNotice: AuctionNotice;
    fetchLotsAgain: () => void;
    authUser: User;
}

const BulkActions: FC<BulkActionsProps> = ({ auctionNotice, fetchLotsAgain, authUser }) => {
    const { t } = useTranslation();
    const {
        selectedLots,
        setSelectedLots,
        selectedMode,
        setSelectedMode,
        lotStageFiltered,
        lotsClose,
    } = useLotsContext();

    const [allChecked, setAllChecked] = useState(false);

    const selectActions = (lotStageFiltered: LotsTab) => {
        const cancel = { value: SelectLotMode.cancel, label: t('info.cancel') };
        const start = { value: SelectLotMode.start, label: t('term.bulk-action.start-bids') };
        const advanceOpen = {
            value: SelectLotMode.advanceOpen,
            label: t('term.bulk-action.next-opens-finished'),
        };
        const advanceRandom = {
            value: SelectLotMode.advanceRandom,
            label: t('term.bulk-action.next-random-finished'),
        };
        const advanceClosed = {
            value: SelectLotMode.advanceClosed,
            label: t('term.bulk-action.next-closed-finished'),
        };
        const finishNegotiation = {
            value: SelectLotMode.finishNegotiation,
            label: t('term.bulk-action.finish-negotiation'),
        };

        const startEndCancel = [
            start,
            cancel,
        ];
        const allActions = [
            ...startEndCancel,
            advanceOpen,
            advanceRandom,
            advanceClosed,
            finishNegotiation,
        ];

        const startedOptions = featureFlagEnabled('habilitarAvancoDeEtapaEmMassa', authUser)
            ? allActions
            : startEndCancel;
        const negotiationOptions = featureFlagEnabled('habilitarAvancoDeEtapaEmMassa', authUser)
            ? [cancel, finishNegotiation]
            : [cancel];
        const options = {
            [LotsTab.started]: startedOptions,
            [LotsTab.not_started]: [start],
            [LotsTab.open_period_ended]: [cancel, advanceOpen],
            [LotsTab.random_period_ended]: [cancel, advanceRandom],
            [LotsTab.close_period_ended]: [cancel, advanceClosed],
            [LotsTab.negotiation]: negotiationOptions,
        };

        const selectedOptions = options[lotStageFiltered] || [];

        return (
            <>
                {selectedOptions.map(({ value, label }) => (
                    <Select.Option key={value} value={value}>
                        {label}
                    </Select.Option>
                ))}
            </>
        );
    };

    const action = methodDisputeDoAction({
        methodDispute: auctionNotice.methodDispute,
        open: () => ({
            [SelectLotMode.advanceOpen]: auctionNotice.isRandomTime
                ? ApiService.startRandomPeriodInLots
                : ApiService.finishLots,
            [SelectLotMode.advanceRandom]: ApiService.finishLots,
        }),
        closed: () => ({
            [SelectLotMode.advanceOpen]: ApiService.startRandomPeriodInLots,
            [SelectLotMode.advanceRandom]: ApiService.startClosePeriodInLots,
            [SelectLotMode.advanceClosed]: () => {
                advanceClosedAction();
            },
        }),
    });

    const advanceClosedAction = async () => {
        const lots = selectedLots.reduce<{
            lotsToFineshed: number[];
            lotsToStartSecond: number[];
        }>(
            (acc, lotId) => {
                const lot = lotsClose.find((lot) => lot.id === lotId);
                if (!lot || !lot?.closeBids) return acc;

                const targetList = noClosedBidAndHaveProviderCallable(lot.closeBids)
                    ? acc.lotsToFineshed
                    : acc.lotsToStartSecond;

                targetList.push(lot.id);
                return acc;
            },
            { lotsToFineshed: [], lotsToStartSecond: [] }
        );

        if (lots.lotsToFineshed.length) {
            await ApiService.finishLots(auctionNotice.id, lots.lotsToFineshed);
        }
        if (lots.lotsToStartSecond.length) {
            await ApiService.startSecondClosePeriodInLots(auctionNotice.id, lots.lotsToStartSecond);
        }
    };

    const selecionaEndpointDeAvanco = async () => {
        const object = action?.();
        if (!object || !selectedMode) return;
        await object[selectedMode](auctionNotice.id, selectedLots);
    };

    const doActionLot = async (message?: string, reasonFrustratedId?: number) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setSelectedLots([]);
            setAllChecked(false);
        });

        addNotification('Sucesso', 'A ação será realizada, aguarde um momento', 'info', 3000);

        switch (selectedMode) {
            case SelectLotMode.start: {
                ApiService.startLots({
                    lotsId: selectedLots,
                    auctionNoticeId: auctionNotice.id,
                });
                break;
            }

            case SelectLotMode.cancel: {
                if (!message) {
                    return;
                }
                ApiService.cancelLots({
                    lotsId: selectedLots,
                    auctionNoticeId: auctionNotice.id,
                    message,
                    reasonFrustratedId,
                });
                break;
            }

            case SelectLotMode.advanceOpen: {
                await selecionaEndpointDeAvanco();
                break;
            }

            case SelectLotMode.advanceClosed: {
                await selecionaEndpointDeAvanco();
                break;
            }

            case SelectLotMode.advanceRandom: {
                await selecionaEndpointDeAvanco();
                break;
            }

            case SelectLotMode.finishNegotiation: {
                ApiService.finishNegotiationLots({
                    lotsId: selectedLots,
                    auctionNoticeId: auctionNotice.id,
                });
                break;
            }
        }

        fetchLotsAgain();
    };

    const onSelectAllLots = (event) => {
        const checked = event.target.checked;
        setAllChecked(checked);

        const selectedLotsSet = new Set(selectedLots);

        const lotsIds = Array.from(document.querySelectorAll('[id^="checkbox-lot:"]'))
            ?.map((el) => Number(el.id.split(':')[1]))
            .filter((lotId) => !!lotId);

        if (checked) {
            lotsIds.forEach((lotId) => selectedLotsSet.add(Number(lotId)));
        } else {
            lotsIds.forEach((lotId) => selectedLotsSet.delete(Number(lotId)));
        }
        const newSelectedLots = Array.from(selectedLotsSet);
        setSelectedLots(newSelectedLots);
    };

    const defaultExecuteButton = () => {
        const disabled = !selectedLots.length || !selectedMode;

        return (
            <Button
                disabled={disabled}
                onClick={() => doActionLot('')}
                style={
                    disabled
                        ? {}
                        : {
                              background: 'var(--platform-primary-color)',
                              color: '#fff',
                          }
                }
            >
                Executar
            </Button>
        );
    };

    const getExecuteButtonByType = () => {
        switch (selectedMode) {
            case SelectLotMode.cancel:
                return (
                    <>
                        <MessagePopover
                            maxWidth='330px'
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.action.cancel.message')}</p>
                                    <p>{t('info.action.cancel.confirmation')}</p>
                                </Wrapper>
                            }
                            onConfirm={(message: string) => doActionLot(message)}
                        >
                            {defaultExecuteButton()}
                        </MessagePopover>
                    </>
                );

            default:
                return (
                    <>
                        <ConfirmationPopover
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{'Deseja continuar com a ação em massa?'}</p>
                                </Wrapper>
                            }
                            maxWidth='240px'
                            onConfirm={() => doActionLot()}
                        >
                            {defaultExecuteButton()}
                        </ConfirmationPopover>
                    </>
                );
        }
    };

    return (
        <Wrapper
            height='50px'
            display='flex'
            alignItems='center'
            padding='0 30px'
            justifyContent={lotStageFiltered === LotsTab.all ? 'center' : 'space-between'}
            bgcolor='#fff6bc'
            position='relative'
            borderBottom='1px #e1e1e1 solid'
        >
            <Wrapper>
                <Checkbox
                    checked={allChecked}
                    onChange={onSelectAllLots}
                    disabled={lotStageFiltered === LotsTab.all || !selectedMode}
                    style={{
                        position: 'absolute',
                        top: '15px',
                        left: '12px',
                        zIndex: '1',
                    }}
                />
            </Wrapper>

            {lotStageFiltered === LotsTab.all ? (
                <Wrapper margin='0 5px' minWidth='105px' alignItems='center' flexBox>
                    <b style={{ fontSize: 18, left: '100px', padding: '0 110px' }}>
                        {t('term.bulk-action.filter-lot-stage')}
                    </b>
                </Wrapper>
            ) : (
                <>
                    <span style={{ fontSize: 15, color: '#444', fontWeight: 600 }}>
                        {selectedLots.length ? (
                            <>
                                <b style={{ fontSize: 18 }}>{String(selectedLots.length)}</b>{' '}
                                {t('term.selected-lots')}
                            </>
                        ) : (
                            `Nenhum lote selecionado`
                        )}
                    </span>
                    <>
                        <Select
                            placeholder='Selecione uma ação'
                            style={{ minWidth: 140 }}
                            value={selectedMode}
                            onChange={(value) => {
                                setSelectedMode(value);

                                if (!selectedMode || selectedMode !== value) {
                                    setSelectedLots([]);
                                    setAllChecked(false);
                                }
                            }}
                        >
                            {selectActions(lotStageFiltered)}
                        </Select>

                        {getExecuteButtonByType()}
                    </>
                </>
            )}
        </Wrapper>
    );
};

export default BulkActions;
