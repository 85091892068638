import { SenderType } from 'Interfaces/senderType';
import useSenderTypeTerms from './hooks/use-sender-type-terms';
import { MessageItem } from 'Components/Chat/interface';
import { pad } from 'helpers/pad';
import { HeadingLevel, Paragraph, TabStopType, TextRun } from 'docx';
import moment from 'moment';
import parseHtmlToTextParts from './parse-html-to-text-parts';

type Terms = ReturnType<typeof useSenderTypeTerms>;

interface ParseMessagesToDocParams {
    terms: Terms;
    messages: MessageItem[];
}

/**
 * @name parseMessagesToDoc
 * @summary - This function return an array of paragraphs with the messages to be exported to a document (docx).
 *
 * @description - This function receives an array of messages and the terms for each sender type and returns an array of paragraphs
 * with the messages to be exported to a document (docx). Each message is composed of a header with the sender name and the message
 * date, the message content and a separator line.
 *
 * @author - &commat;nascjoao &lt;oi&commat;nasc.dev&gt;
 */
export default function parseMessagesToDoc(params: ParseMessagesToDocParams) {
    const messages = params.messages.flatMap((msg) => {
        const messageAuthor = {
            [SenderType.auction]: params.terms[SenderType.auction],
            [SenderType.system]: params.terms[SenderType.system],
            [SenderType.provider]: msg.participate?.providerAuctionCode
                ? `${params.terms[SenderType.provider]} ${pad(
                      msg.participate?.providerAuctionCode
                  )}`
                : params.terms[SenderType.provider],
        };

        const gap = [
            new TextRun({
                text: '\u00A0\u00A0\u00A0',
            }),
            new TextRun({
                text: '\t',
            }),
        ];

        const messageDateTime = `${moment(msg.createdAt).format('L')} ${moment(
            msg.createdAt
        ).format('HH:mm:ss')}`;

        const header = new Paragraph({
            children: [
                new TextRun({
                    text: messageAuthor[msg.senderType],
                    bold: true,
                    font: 'Arial',
                    size: 20,
                }),
                ...gap,
                new TextRun({
                    text: messageDateTime,
                    font: 'Arial',
                    size: 20,
                }),
            ],
            tabStops: [
                {
                    type: TabStopType.RIGHT,
                    position: 9000,
                },
            ],
            spacing: { after: 200 },
            heading: HeadingLevel.HEADING_3,
        });

        const message = new Paragraph({
            children: parseHtmlToTextParts(msg.message).map((part) => {
                return new TextRun({
                    text: part.content,
                    bold: part.bold,
                    font: 'Arial',
                    size: 18,
                });
            }),
        });

        const separatorLine = new Paragraph({
            children: [],
            border: {
                bottom: { color: '000000', space: 1, size: 6, style: 'single' },
            },
            spacing: { after: 200 },
        });

        return [header, message, separatorLine];
    });

    return messages;
}
