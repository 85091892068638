import React from 'react';
import { InitialProps, InitialState } from './props';
import ApiService from '../../services/api';
import { SocketHandler } from '../../hoc/Socket';
import moment from 'moment';
import Wrapper from '../../Components/Common/wrapper';
import { AuctionNoticeLot } from '../../Interfaces/auctionNoticeLot';
import ErrorMessage from '../../Components/ErrorMessage';
import Chat from '../../Components/Chat';
import LotSelectedResume from '../../Components/LotSelectedResume';
import LotListArea from '../../Components/LotListArea';
import PageHeader from '../../Components/Common/PageHeader';
import RefreshPageHandler from '../../Components/RefreshPageHandler';
//@ts-ignore
import ReactNotification from 'react-notifications-component';
import { timeout } from 'helpers/timer';
import { Spin, SpinArea } from '../../Components/Common/Spin';
import StatusConnection from '../../Components/StatusConnection';
import { isCitizen, isProvider } from '../../helpers/permissions';
import { withTranslation } from 'react-i18next';

type i18nProps = {
    t: (args: any) => any;
};

class InitialClass extends React.Component<InitialProps & i18nProps, InitialState> {
    constructor(props: InitialProps & i18nProps) {
        super(props);

        this.state = {
            socketConnection: undefined,
            auctionNotice: undefined,
            authUser: {
                ...props.authUser,
                providerAuctionCode: null,
            },
            serverTimestamp: {
                difference: moment.duration(),
                timestamp: +new Date(),
            },
            auctionNoticeLotSelected: {} as AuctionNoticeLot,
            error: {} as { type: string },
        };
        this.getTimestamp();
    }

    getTimestamp = async () => {
        let error: any;
        const started = moment().valueOf();
        const serverTimestamp = await ApiService.timestamp((err: any) => {
            error = err;
        });
        const ended = moment().valueOf();

        if (!serverTimestamp || error) {
            return this.setState({
                error: { type: 'timestamp' },
            });
        }

        const { timestamp } = serverTimestamp;
        const requestDiff = moment.duration(moment(ended).diff(moment(started))).asSeconds();
        const currentDateOffset = moment
            .duration(moment(timestamp).diff(moment()))
            .add(requestDiff, 'seconds');

        if (Math.abs(currentDateOffset.asSeconds()) > 1) {
            return this.setState(
                {
                    serverTimestamp: {
                        timestamp: timestamp,
                        difference: currentDateOffset,
                    },
                },
                () => this.getAuctionNotice()
            );
        }
        this.getAuctionNotice();
    };

    getAuctionNotice = async () => {
        const auctionNotice = await ApiService.getAuctionNotice(this.props.auctionNoticeId);

        auctionNotice &&
            this.setState(
                {
                    auctionNotice,
                },
                () => this.getAuctionProviderCode()
            );
    };

    getAuctionProviderCode = async () => {
        const { authUser, auctionNotice } = this.state;

        if (!isProvider(authUser) || !auctionNotice?.id) {
            return;
        }

        const { providerAuctionCode } = await ApiService.getAuctionProviderCode(auctionNotice.id);

        this.setState({
            authUser: {
                ...authUser,
                providerAuctionCode: providerAuctionCode || undefined,
            },
        });
    };

    onAuctionSelected = (auctionSelected: AuctionNoticeLot) => {
        const { auctionNoticeLotSelected } = this.state;

        if (auctionNoticeLotSelected && auctionSelected?.id === auctionNoticeLotSelected?.id)
            return;

        this.setState({
            auctionNoticeLotSelected: { ...auctionSelected },
        });
    };

    onUpdateSelectedLot = (lot: AuctionNoticeLot) => {
        const { auctionNoticeLotSelected } = this.state;

        if (auctionNoticeLotSelected && lot?.id !== auctionNoticeLotSelected?.id) {
            return;
        }

        this.setState({
            auctionNoticeLotSelected: { ...lot },
        });
    };

    onSocketConnectionCreated = (socketUrl: string) => {
        const { initSocket } = this.props;
        const { socketConnection } = this.state;

        // se já tem uma conexão, fecha antes de iniciar outra
        if (!!socketConnection) {
            socketConnection.close();
        }

        this.setState({
            socketConnection: initSocket(socketUrl) || null,
        });
    };

    providerCodeCreated = (providerAuctionCode: number) => {
        const { authUser } = this.state;

        this.setState({
            authUser: {
                ...authUser,
                providerAuctionCode,
            },
        });
    };

    renderError() {
        const { t } = this.props;
        let errorMessage: string = t('info.error.fetch.initial.data');

        switch (this.state.error?.type) {
            case 'timestamp':
                errorMessage = t('error.get.server.time');
                break;

            default:
                errorMessage = t('error.get.process.info');
                break;
        }

        return <ErrorMessage message={errorMessage} />;
    }

    viewToRender = () => {
        const {
            auctionNotice,
            socketConnection,
            authUser,
            auctionNoticeLotSelected,
            serverTimestamp,
            error,
        } = this.state;

        const { t } = this.props;

        if (error?.type) {
            return this.renderError();
        }

        if (auctionNotice === null || socketConnection === null) {
            timeout(() => window.location.reload(), 10000);

            return (
                <ErrorMessage
                    description={t('info.page.reload')}
                    message={t('info.missing.data')}
                />
            );
        }

        return auctionNotice ? (
            <>
                <ReactNotification />
                {socketConnection && (
                    <>
                        {/* refresh da página solicitado pelo fornecedor apenas para != cidadão*/}
                        {!isCitizen(authUser) ? (
                            <RefreshPageHandler
                                authUser={authUser}
                                socketConnection={socketConnection}
                            />
                        ) : null}
                        <StatusConnection socketConnection={socketConnection} />
                    </>
                )}
                <PageHeader auctionNotice={auctionNotice} serverTimestamp={serverTimestamp} />
                <Wrapper
                    flexBox
                    className='viewArea'
                    width='100%'
                    height='calc(100vh - 70px)'
                    justifyContent='space-between'
                >
                    <Chat
                        key={`chats:${auctionNotice?.id}`}
                        socketConnection={socketConnection}
                        authUser={authUser}
                        auctionNotice={auctionNotice}
                    />
                    <LotListArea
                        key={`lotListArea:${auctionNotice.id}`}
                        serverTimestamp={serverTimestamp}
                        auctionNotice={auctionNotice}
                        authUser={authUser}
                        socketConnection={socketConnection}
                        auctionNoticeLotSelected={auctionNoticeLotSelected}
                        onAuctionSelected={this.onAuctionSelected}
                        onSocketConnectionCreated={this.onSocketConnectionCreated}
                        onUpdateSelectedLot={this.onUpdateSelectedLot}
                        providerCodeCreated={this.providerCodeCreated}
                    />
                    <LotSelectedResume
                        key={`lotSelectedResume:${auctionNotice.id}`}
                        auctionNotice={auctionNotice}
                        authUser={authUser}
                        lotSelected={auctionNoticeLotSelected}
                        serverTimestamp={serverTimestamp}
                        socketConnection={socketConnection}
                    />
                </Wrapper>
            </>
        ) : (
            <Wrapper height='100vh'>
                <SpinArea>
                    <Spin />
                </SpinArea>
            </Wrapper>
        );
    };

    render() {
        return this.viewToRender();
    }
}

export const Initial = withTranslation()(SocketHandler(InitialClass));
