type TextPart = {
    content: string;
    bold?: boolean;
};

/**
 * @name parseHtmlToTextParts
 * @summary - This function parses an HTML string into an array of text parts. Currently, it only supports <strong> tags.
 *
 * @description - This function receives an HTML string and returns an array of text parts. Each text part has a content property
 * which is the text content of the part and an optional bold property which is true if the part is bold.
 * The function only supports <strong> tags.
 *
 * @author - &commat;nascjoao &lt;oi&commat;nasc.dev&gt;
 */
export default function parseHtmlToTextParts(html: string): TextPart[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const body = doc.body;
    const result: TextPart[] = [];

    body.childNodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            result.push({ content: node.textContent || '' });
        }

        if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'STRONG') {
            result.push({ content: node.textContent || '', bold: true });
        }
    });

    return result;
}
