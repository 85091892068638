import { ProviderValue } from './providerValues';
import { StageLot } from './stageLot';
import { PauseLot } from './pauseLot';
import { Decline } from './decline';
import { CloseBid } from './closeBids';

export interface LotPosition {
    position: number;
    uniqueBids: number;
    percent: number | undefined;
}

interface CustomFields {
    stage: StageLot;
    position: LotPosition | undefined;
    convokedProvider: ProviderValue | undefined;
    winnerProvider: ProviderValue | undefined;
    winnerProviderId?: number;
    extensionTimeIsExpired: boolean;
}

export enum LotTiebreakerRound {
    NONE,
    ME_EPP,
    WINNER,
}

export interface AuctionNoticeLot extends CustomFields {
    id: number;
    auctionNoticeId: number;
    proposalCount?: number;
    item: number;
    bidAmountDifference: string;
    showReferenceValue: number;
    showReferenceValueToWinner: number;
    bidStartDate: string | null;
    extensionTime: string | null;
    // bestBid tem o valor do "Bid" ou "ProviderValue"
    bestBid: string | null;
    // providerBestBid tem o valor do "Bid" ou "ProviderValue" do proprio fornecedor logado
    providerBestBid: string | null;
    additionalExpense?: string | null
    bidRenewDate: string | null;
    quotaId: number | null;
    pauses: PauseLot[];
    isExclusiveToSimpleCompany?: number;
    valueReference?: string;
    status: any;
    oldStatus: string;
    temporaryDecline?: Decline;
    definiveDecline?: Decline;
    lotStage: string;
    allowMultipleWinner?: number;
    isUnclassified?: boolean;
    tiebreakerRound: LotTiebreakerRound;
    lotInBulkAction: boolean;
    closeBids?: CloseBid[];
    secondDeadlineIntentAppeal: string;
    hasWinner: boolean;
}

export interface AuctionNoticeLotResponse {
    count: number;
    lots: AuctionNoticeLot[];
}

export interface IAuctionNoticeLot {
    [key: string]: AuctionNoticeLot;
}
