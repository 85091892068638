import axios, { AxiosResponse } from 'axios';
import { CONSTANTS } from '../../constants';
import { ChatFavoriteMessage, MessageItem } from '../../Components/Chat/interface';
import { addNotification } from '../../helpers/notification';
import { AuctionNoticeLotResponse } from '../../Interfaces/auctionNoticeLot';
import { AuctionNoticeLotItem } from '../../Interfaces/auctionNoticeLotItem';
import { Bid } from '../../Interfaces/bid';
import { AuctionNotice } from '../../Interfaces/auctionNotice';
import { Participate } from '../../Interfaces/participate';
import { ProviderValue } from '../../Interfaces/providerValues';
import { CloseBid } from '../../Interfaces/closeBids';
import { AuctionNoticeParticipate } from '../../Interfaces/auctionNoticeParticipate';
import i18n from '../../i18n/config';
import { Platform } from '../../Interfaces/platform';
import { CreateDecline, DeferDecline, ListAuctionDeclines, ProposalToConfirm } from './interfaces';
import { Decline } from '../../Interfaces/decline';
import { getDataFromAuthHash } from '../../helpers/token';

import {
    MultipleWinners,
    MultipleWinnersStatus,
    MultipleWinnersToCover,
} from '../../Interfaces/multipleWinners';
import { ReasonFrustrated } from 'Interfaces/reasonFrustrated';

interface Paginated<T> {
    data: T;
    count: number;
    total: number;
    pageCount: number;
}

const getBearer = () => {
    const hash = window.location.pathname.split('/')[2];
    const data = getDataFromAuthHash(hash as string);

    if (!data) {
        return undefined;
    }

    const { token } = data;

    if (!token) {
        return undefined;
    }

    return `Bearer ${token}`;
};

export const getService = () => {
    const hash = window.location.pathname.split('/')[2];
    const data = getDataFromAuthHash(hash as string);

    if (!data) {
        return undefined;
    }
    return data.service ? data.service : undefined;
};

const httpDefault = () => {
    return {
        baseURL: CONSTANTS.API_URL,
        timeout: 15000,
        headers: {
            Authorization: getBearer(),
            'X-Service-Dispute': getService(),
        },
    };
};

const getExceptionMessage = (error: any): string => {
    const currentLanguage = i18n.language;

    if (currentLanguage === 'en') {
        return error?.response?.data?.response?.errorMessage?.en;
    }

    return error?.response?.data?.response?.errorMessage?.pt;
};

const interceptorResponse = async (
    axios: Promise<AxiosResponse>,
    showErrorMessage: boolean = false,
    errorCb?: Function
) => {
    try {
        const http = await axios;
        return http.data;
    } catch (error: any) {
        if (error?.response?.data?.response?.errorToken) {
            addNotification(
                i18n.t('info.default.api.error.title'),
                getExceptionMessage(error),
                'danger',
                5000
            );

            if (errorCb) {
                return errorCb(error.response?.data ?? !!error);
            }
        } else if (showErrorMessage) {
            addNotification(
                i18n.t('info.default.api.error.title'),
                i18n.t('info.default.api.error.message'),
                'danger',
                5000
            );
        }

        if (errorCb) {
            return errorCb(error.response?.data ?? !!error);
        }

        throw error;
    }
};

const ApiService = {
    getAuctionNoticeLots: async (
        auctionNoticeId: number,
        query: any
    ): Promise<AuctionNoticeLotResponse> => {
        const queryString = new URLSearchParams(query);

        return await interceptorResponse(
            axios.get(`/auction-notices/${auctionNoticeId}/lots?${queryString}`, httpDefault())
        );
    },

    startDisputeToDefineCollocations: async (
        { auctionNoticeId, auctionLotId }: { auctionNoticeId: number; auctionLotId: number; }
    ): Promise<AuctionNoticeLotResponse> => {
        return await interceptorResponse(
            axios.post(`/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/startDisputeToDefineCollocations`, undefined, httpDefault()),
        );
    },

    changeLotStatusToNegotiation: async (
        { auctionNoticeId, auctionLotId }: { auctionNoticeId: number; auctionLotId: number; }
    ): Promise<any> => {
        return await interceptorResponse(
            axios.put(`/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/negotiation`, undefined, httpDefault()),
        );
    },

    startVerificationOfProposalCompliance: async (
        { auctionNoticeId, auctionLotId }: { auctionNoticeId: number; auctionLotId: number; }
    ): Promise<any> => {
        return await interceptorResponse(
            axios.put(`/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/start-verification-of-proposal-compliance`, undefined, httpDefault()),
        );
    },

    confirmProposalCompliance: async ( auctionNoticeId: number, auctionLotId: number, proposalId: number,): Promise<any> => {
        return await interceptorResponse(
            axios.put(`/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/proposal/${proposalId}/confirm-proposal-compliance`, undefined, httpDefault()),
        );
    },

    getAuctionNoticeLotItems: async (
        auctionNoticeId: number,
        lotId: number
    ): Promise<AuctionNoticeLotItem[]> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/items`,
                httpDefault()
            )
        );
    },

    getBestBid: async (auctionNoticeId: number, lotId: number): Promise<Bid> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/best-bid`,
                httpDefault()
            ),
            false
        );
    },

    getProviderBestBid: async (auctionNoticeId: number, lotId: number): Promise<Bid> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/provider-best-bid`,
                httpDefault()
            ),
            false
        );
    },

    bid: async (auctionNoticeId: number, data: any, errorCb: Function) => {
        return await interceptorResponse(
            axios.post(`/auction-notices/${auctionNoticeId}/bid`, { ...data }, httpDefault()),
            false,
            errorCb
        );
    },

    closeCover: async (auctionNoticeId: number, data: any, errorCb: Function): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${data.lotId}/close-cover`,
                { ...data },
                httpDefault()
            ),
            false,
            errorCb
        );
    },

    getBids: async (auctionNoticeId: number, lotId: number): Promise<Bid[]> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/bids`,
                httpDefault()
            )
        );
    },

    getCloseBids: async (auctionNoticeId: number, lotId: number): Promise<CloseBid[]> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/close-bids`,
                httpDefault()
            ),
            false
        );
    },

    getAuctionNotice: async (auctionNoticeId: string): Promise<AuctionNotice> => {
        return await interceptorResponse(
            axios.get(`/auction-notices/${auctionNoticeId}`, httpDefault())
        );
    },

    getAuctionNoticeMessages: async (
        auctionNoticeId: number,
        { ts, offset, limit, q }
    ): Promise<MessageItem[]> => {
        return await interceptorResponse(
            axios.get(`/auction-notices/${auctionNoticeId}/chats`, {
                ...httpDefault(),
                params: {
                    ts,
                    offset,
                    limit,
                    q,
                },
            })
        );
    },

    createMessage: async (
        auctionNoticeId: number,
        message: { message: string }
    ): Promise<Paginated<MessageItem[]>> => {
        return await interceptorResponse(
            axios.post(`/auction-notices/${auctionNoticeId}/chats`, { ...message }, httpDefault())
        );
    },

    listFavoriteMessages: async (): Promise<ChatFavoriteMessage[]> => {
        return await interceptorResponse(axios.get(`/chat/favorite-message`, httpDefault()));
    },

    startAuctionNoticeLot: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/start`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    pauseAuctionNoticeLot: async ({
        auctionNoticeId,
        auctionLotId,
        message,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
        message: string;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/pause`,
                { message },
                httpDefault()
            ),
            true
        );
    },

    unPauseAuctionNoticeLot: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/unpause`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    finishAuctionNoticeLot: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/finish`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    finishLots: async (processId: number, lotsId: number[]): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${processId}/auction-notice-lots/finish-bulk`,
                { lotsId },
                httpDefault()
            ),
            true
        );
    },

    startLots: async ({
        auctionNoticeId,
        lotsId,
    }: {
        auctionNoticeId: number;
        lotsId: number[];
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/start-bulk`,
                { lotsId },
                httpDefault()
            ),
            true
        );
    },

    cancelLots: async ({
        auctionNoticeId,
        lotsId,
        message,
        reasonFrustratedId,
    }: {
        auctionNoticeId: number;
        lotsId: number[];
        message: string;
        reasonFrustratedId?: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/cancel-bulk`,
                { message, reasonFrustratedId, lotsId },
                httpDefault()
            ),
            true
        );
    },

    cancelLot: async ({
        auctionNoticeId,
        auctionLotId,
        message,
        reasonFrustratedId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
        message: string;
        reasonFrustratedId?: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/cancel`,
                { message, reasonFrustratedId },
                httpDefault()
            ),
            true
        );
    },

    finishNegotiationLots: async ({
        auctionNoticeId,
        lotsId,
    }: {
        auctionNoticeId: number;
        lotsId: number[];
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/finish-negotiation-bulk`,
                { lotsId },
                httpDefault()
            ),
            true
        );
    },

    finishNegotiationAuctionNoticeLot: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/finish-negotiation`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    renewCoverTimeLot: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.patch(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/renewCover`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    renewAuctionNoticeLot: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/renew`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    getAuctionNoticeParticipants: async (
        auctionNoticeId: number,
        auctionNoticeLotId: number,
        query: any
    ): Promise<{
        participates: Participate[];
        count: number;
    }> => {
        const queryString = new URLSearchParams(query);

        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/lots/${auctionNoticeLotId}/participates?${queryString}`,
                httpDefault()
            )
        );
    },

    auctioneerDeleteBid: async (
        auctionNoticeId: number,
        lotId: number,
        bidId: number
    ): Promise<Bid[]> => {
        return await interceptorResponse(
            axios.delete(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/bids/${bidId}`,
                httpDefault()
            ),
            true
        );
    },

    getPositionLot: async (auctionNoticeId: number, lotId: number): Promise<Bid> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/position`,
                httpDefault()
            ),
            false
        );
    },

    getProviderValues: async (auctionNoticeId: number, lotId: number): Promise<ProviderValue[]> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/provider-values`,
                httpDefault()
            ),
            false
        );
    },

    getPaginatedProviderValues: async (
        auctionNoticeId: number,
        lotId: number,
        query: any
    ): Promise<{
        providerValues: ProviderValue[];
        count: number;
    }> => {
        const queryString = new URLSearchParams(query);

        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/paginated-provider-values?${queryString}`,
                httpDefault()
            ),
            false
        );
    },

    callProvider: async (auctionNoticeId: number, lotId: number) => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/call`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    cover: async (auctionNoticeId: number, data: any, errorCb: Function) => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${data.lotId}/cover`,
                { ...data },
                httpDefault()
            ),
            false,
            errorCb
        );
    },

    declineCover: async (auctionNoticeId: number, lotId: number, errorCb: Function) => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/decline-cover`,
                undefined,
                httpDefault()
            ),
            false,
            errorCb
        );
    },

    updateProviderValue: async (
        auctionNoticeId: number,
        lotId: number,
        data: any,
        errorCb: Function
    ): Promise<any> => {
        return await interceptorResponse(
            axios.put(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/provider-values/${data.providerValueId}`,
                { ...data },
                httpDefault()
            ),
            false,
            errorCb
        );
    },

    clearLot: async (lotId: number, errorCb: Function): Promise<any> => {
        return await interceptorResponse(
            axios.post(`/test-api/lot/${lotId}/clear`, undefined, httpDefault()),
            true,
            errorCb
        );
    },

    timestamp: async (errorCb: Function): Promise<any> => {
        return await interceptorResponse(axios.get(`/app/timestamp`, httpDefault()), true, errorCb);
    },

    startCloseAuctionNoticeLot: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/start-close`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    startClosePeriodInLots: async (processId: number, lotsId: number[]): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${processId}/auction-notice-lots/start-close-bulk`,
                { lotsId },
                httpDefault()
            ),
            true
        );
    },

    startSecondClosePeriodInLots: async (processId: number, lotsId: number[]): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${processId}/auction-notice-lots/start-second-close-bulk`,
                { lotsId },
                httpDefault()
            ),
            true
        );
    },

    startSecondCloseAuctionNoticeLot: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/start-second-close`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    reopenNegotiation: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/reopen-negotiation`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    getProviderBestCloseBid: async (auctionNoticeId: number, lotId: number): Promise<CloseBid> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/provider-best-close-bid`,
                httpDefault()
            ),
            false
        );
    },

    getAuctionProviderCode: async (auctionNoticeId: number): Promise<AuctionNoticeParticipate> => {
        return await interceptorResponse(
            axios.get(`/auction-notices/${auctionNoticeId}/provider-auction-code`, httpDefault())
        );
    },

    requestRefreshPage: async ({ auctionNoticeId }: { auctionNoticeId: number }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/request-refresh`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    resetProviderValue: async ({
        auctionNoticeId,
        lotId,
        providerValueId,
    }: {
        auctionNoticeId: number;
        lotId: number;
        providerValueId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/provider-values/${providerValueId}/original-value`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    requestCancelBid: async ({
        auctionNoticeId,
        lotId,
        bidId,
        errorCb,
    }: {
        auctionNoticeId: number;
        lotId: number;
        bidId: number;
        errorCb: Function;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/delete-bid-request`,
                { bidId },
                httpDefault()
            ),
            false,
            errorCb
        );
    },

    requestSendChat: async ({ auctionNoticeId }: { auctionNoticeId: number }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/request-send-message`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    enableProviderChat: async ({
        auctionNoticeId,
        data,
    }: {
        auctionNoticeId: number;
        data: any;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.put(
                `/auction-notices/${auctionNoticeId}/chat-enable-providers`,
                data,
                httpDefault()
            ),
            true
        );
    },

    getEnableProviderChat: async ({
        auctionNoticeId,
    }: {
        auctionNoticeId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/chat-enable-providers/unique`,
                httpDefault()
            ),
            true
        );
    },

    getEnableProvidersChat: async ({
        auctionNoticeId,
    }: {
        auctionNoticeId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.get(`/auction-notices/${auctionNoticeId}/chat-enable-providers`, httpDefault()),
            true
        );
    },

    desclassifyProviderValue: async ({
        auctionNoticeId,
        auctionLotId,
        providerValueId,
        justification
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
        providerValueId: number;
        justification?: string;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/provider-values/${providerValueId}/declassify`,
                { justification },
                httpDefault()
            )
        );
    },

    selectWinnerAndDeclassifyOthers: async ({
        auctionNoticeId,
        auctionLotId,
        providerValueId,
        justification,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
        providerValueId: number;
        justification?: string
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/provider-values/${providerValueId}/selectWinner`,
                { justification },
                httpDefault()
            )
        );
    },

    repeatSecondClosePeriod: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/repeat-close`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    finishRepeatSecondClosePeriod: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/finish-repeat-close`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    getMe: async (): Promise<any> => {
        return await interceptorResponse(axios.get('/users/me', httpDefault()), true);
    },

    getBidHistory: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/bid-history`,
                httpDefault()
            ),
            true
        );
    },

    startRandomPeriod: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/start-random`,
                undefined,
                httpDefault()
            ),
            true
        );
    },

    startRandomPeriodInLots: async (processId: number, lotsId: number[]): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${processId}/auction-notice-lots/start-random-bulk`,
                { lotsId },
                httpDefault()
            ),
            true
        );
    },

    getPlatform: async (): Promise<Platform> => {
        return await interceptorResponse(axios.get(`/platforms`, httpDefault()));
    },

    createDecline: async (data: CreateDecline, errorCb?: Function): Promise<any> => {
        return await interceptorResponse(
            axios.post(`/decline/createDecline`, data, httpDefault()),
            false,
            errorCb
        );
    },

    deferDecline: async (data: DeferDecline): Promise<any> => {
        return await interceptorResponse(
            axios.post(`/decline/deferDecline`, data, httpDefault()),
            true
        );
    },

    listAuctionNoticeDeclines: async (data: ListAuctionDeclines): Promise<Decline[]> => {
        return await interceptorResponse(
            axios.post(`/decline/listAuctionNoticeDeclines`, data, httpDefault()),
            true
        );
    },

    listReasonFrustrated: async (): Promise<ReasonFrustrated[]> => {
        return await interceptorResponse(
            axios.post(`/reason-frustrated/listReasonFrustrated`, undefined, httpDefault()),
            true
        );
    },

    undoDesclassifyProviderValue: async ({
        auctionNoticeId,
        lotId,
        providerValueId,
    }: {
        auctionNoticeId: number;
        lotId: number;
        providerValueId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${lotId}/provider-values/${providerValueId}/undo-declassify`,
                undefined,
                httpDefault()
            )
        );
    },

    doIntentionToAppeal: async (
        auctionId: number,
        auctionLotId: number,
        message: string,
        errorCb: Function
    ): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionId}/auction-notice-lots/${auctionLotId}/doIntentionToAppeal`,
                { message },
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    doStartSecondIntentionToAppeal: async (
        auctionId: number,
        auctionLotId: number,
        enableToAllLots: boolean,
    ): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionId}/auction-notice-lots/${auctionLotId}/doStartSecondIntentionToAppeal`,
                { enableToAllLots },
                httpDefault()
            ),
            true,
        );
    },

    getRequestedAdditionalDocuments: async (
        auctionId: number,
        auctionLotId: number,
        errorCb: Function
    ): Promise<{ requestedAdditionalDocuments: number }> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionId}/auction-notice-lots/${auctionLotId}/getRequestedAdditionalDocuments`,
                undefined,
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    revertCancelLot: async ({
        auctionNoticeId,
        auctionLotId,
    }: {
        auctionNoticeId: number;
        auctionLotId: number;
    }): Promise<any> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionNoticeId}/auction-notice-lots/${auctionLotId}/revert-cancel`,
                undefined,
                httpDefault()
            ),
            true
        );
    },
    
    checkIsWinner: async (
        lotId: number
    ): Promise<boolean> => {
        return await interceptorResponse(
            axios.get(`/auction-notices/auction-notice-lots/${lotId}/check-is-winner`, httpDefault()),
            true
        );
    },

    getWinnersAdditionalDocuments: async (
        processId: number,
        lotId: number
    ): Promise<
        { proposalId: number; requestedAdditionalDocuments: boolean; providerAuctionCode: number }[]
    > => {
        return await interceptorResponse(
            axios.get(`/multiple-winners/${processId}/lots/${lotId}/winners`, httpDefault()),
            true
        );
    },

    listMultipleWinners: async (
        auctionId: number,
        auctionLotId: number,
        errorCb: Function
    ): Promise<MultipleWinners[]> => {
        return await interceptorResponse(
            axios.post(
                '/multiple-winners/listMultipleWinners',
                { lotId: auctionLotId, auctionId },
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    listMultipleWinnerToCover: async (
        auctionId: number,
        errorCb: Function
    ): Promise<MultipleWinnersToCover[]> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionId}/listMultipleWinnerToCover`,
                { auctionId },
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    multipleWinnerCover: async (
        auctionId: number,
        auctionLotId: number,
        providerValueId: number,
        value: number | undefined,
        type: 'full' | 'custom',
        reason?: string,
        errorCb?: Function
    ): Promise<{ ok: boolean }> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionId}/auction-notice-lots/${auctionLotId}/provider-values/${providerValueId}/multipleWinnerCover`,
                { value, type, reason },
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    multipleWinnerReject: async (
        auctionId: number,
        auctionLotId: number,
        providerValueId: number,
        errorCb: Function
    ): Promise<{ ok: boolean }> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionId}/auction-notice-lots/${auctionLotId}/provider-values/${providerValueId}/multipleWinnerReject`,
                undefined,
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    callToMultipleWinnerCover: async (
        auctionId: number,
        auctionLotId: number,
        providerValueId: number,
        errorCb: Function
    ): Promise<{ ok: boolean }> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionId}/auction-notice-lots/${auctionLotId}/provider-values/${providerValueId}/callToMultipleWinnerCover`,
                undefined,
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    updateMultipleWinnerCover: async (
        auctionId: number,
        auctionLotId: number,
        providerValueId: number,
        status: MultipleWinnersStatus,
        errorCb: Function
    ): Promise<{ ok: boolean }> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionId}/auction-notice-lots/${auctionLotId}/provider-values/${providerValueId}/updateMultipleWinnerCover`,
                { status },
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    toggleRequestAdditionalDocuments: async (
        proposalId: number,
        data: { requested: boolean },
        errorCb: Function
    ): Promise<any> => {
        return await interceptorResponse(
            axios.patch(
                `/auction-notices/auction-notice-lot-proposal/${proposalId}/request`,
                data,
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    toggleShowReferenceValueToWinner: async (
        processId: number,
        lotId: number,
        showReferenceValueToWinner: boolean,
        errorCb: Function
    ): Promise<any> => {
        return await interceptorResponse(
            axios.patch(
                `/auction-notices/${processId}/auction-notice-lots/${lotId}/toggle-reference-value-to-winner`,
                { showReferenceValueToWinner },
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    getProposalsToConfirm: async (
        processId: number,
    ): Promise<ProposalToConfirm[]> => {
        return await interceptorResponse(
            axios.get(
                `/auction-notices/${processId}/auction-notice-lot-proposal/proposals-to-confirm`,
                httpDefault()
            ),
        );
    },

    showReferenceValue: async (
        auctionId: number,
        auctionLotId: number,
        errorCb: Function
    ): Promise<{ showReferenceValue: number }> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionId}/auction-notice-lots/${auctionLotId}/showReferenceValue`,
                undefined,
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    declassifyMultipleWinners: async (
        auctionId: number,
        lotId: number,
        position: number,
        errorCb: Function
    ): Promise<{ ok: boolean }> => {
        return await interceptorResponse(
            axios.post(
                `/multiple-winners/declassifyMultipleWinners`,
                { auctionId, lotId, position },
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    ignoreMultipleWinners: async (
        auctionId: number,
        lotId: number,
        providerValueId: number,
        errorCb: Function
    ): Promise<{ ok: boolean }> => {
        return await interceptorResponse(
            axios.post(
                `/auction-notices/${auctionId}/auction-notice-lots/${lotId}/provider-values/${providerValueId}/multipleWinnerIgnore`,
                undefined,
                httpDefault()
            ),
            true,
            errorCb
        );
    },

    verifyExtensionTimeIsExpired: async (
        token: string,
        queryString?: number
    ): Promise<{ isExpired: boolean }> => {
        const url = `${process.env.REACT_APP_API_GATEWAY_SERVICES_URL}/lambda/app/check-radomtime-expiration?param=${queryString}`;
        return await interceptorResponse(
            axios.get(url, {
                timeout: 15000,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            true
        );
    },
};

export default ApiService;
