import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Participate } from '../../Interfaces/participate';
import { LotsTab } from 'Components/LotListArea';
import { CloseBid } from 'Interfaces/closeBids';

export interface LotClose {
    id: number;
    closeBids?: CloseBid[];
}

export type LotsContextType = {
    selectedLots: number[];
    setSelectedLots: React.Dispatch<React.SetStateAction<number[]>>;
    lotsClose: LotClose[];
    setLotsClose: React.Dispatch<React.SetStateAction<LotClose[]>>;
    selectedMode: SelectLotMode | undefined;
    setSelectedMode: React.Dispatch<React.SetStateAction<SelectLotMode | undefined>>;
    participants: Participate[];
    setParticipants: React.Dispatch<React.SetStateAction<Participate[]>>;
    lotStageFiltered: LotsTab;
    setLotStageFiltered: React.Dispatch<React.SetStateAction<LotsTab>>;
    addNewClose: (lotUpdated: LotClose) => void;
};

export const LotsContext = createContext<LotsContextType>({
    selectedLots: [],
    setSelectedLots: () => {},
    lotsClose: [],
    setLotsClose: () => {},
    selectedMode: undefined,
    setSelectedMode: () => {},
    participants: [],
    setParticipants: () => {},
    lotStageFiltered: LotsTab.all,
    setLotStageFiltered: () => {},
    addNewClose: () => {},
});

export enum SelectLotMode {
    start = 'start',
    cancel = 'cancel',
    advanceOpen = 'advanceOpen',
    advanceClosed = 'advanceClosed',
    advanceRandom = 'advanceRandom',
    finishNegotiation = 'finishNegotiation',
}

export const useLotsContext = () => useContext(LotsContext);

export const LotsContextProvider = ({ children }: { children: ReactNode }) => {
    const [selectedLots, setSelectedLots] = useState<number[]>([]);
    const [lotsClose, setLotsClose] = useState<LotClose[]>([]);
    const [selectedMode, setSelectedMode] = useState<SelectLotMode | undefined>(undefined);
    const [participants, setParticipants] = useState<Participate[]>([]);
    const [lotStageFiltered, setLotStageFiltered] = useState<LotsTab>(LotsTab.all);


    const addNewClose = (newClose: LotClose) => {
        setLotsClose((prev) => {
            const exist = prev.find((lotPrev) => lotPrev.id === newClose.id);
            if (exist) {
                return prev.map((lotPrev) =>
                    lotPrev.id === newClose.id ? { ...lotPrev, ...newClose } : lotPrev
                );
            }
            return [...prev, newClose];
        });
    };

    return (
        <LotsContext.Provider
            value={{
                selectedLots,
                setSelectedLots,
                lotsClose,
                setLotsClose,
                selectedMode,
                setSelectedMode,
                participants,
                setParticipants,
                lotStageFiltered,
                setLotStageFiltered,
                addNewClose,
            }}
        >
            {children}
        </LotsContext.Provider>
    );
};
