import { MessageItem } from 'Components/Chat/interface';
import { AuctionNotice } from 'Interfaces/auctionNotice';
import { Document, HeadingLevel, Paragraph, TextRun } from 'docx';
import moment from 'moment';
import useSenderTypeTerms from './use-sender-type-terms';
import parseMessagesToDoc from '../parse-messages-to-doc';

/**
 * @name useGenerateDocument
 * @summary - This hook returns a function that generates a document with the chat messages.
 *
 * @description - This hook is used to generate a document with the chat messages. It receives the chat messages and the auction notice.
 * The document has the following structure:
 * - Heading 1: The auction number.
 * - Heading 2: The document title. In this case, "Chat da Sala de Disputa".
 * - Paragraph: The date and time when the document was exported.
 * - Paragraphs: The chat messages.
 *
 * Each message has the following structure:
 * - Sender name: The name of the sender. It can be the auctioneer, the system or a provider.
 * - Date and time: The date and time when the message was sent.
 * - Message: The message content.
 *
 * @example
 * const generateDocument = useGenerateDocument({ chatMessages, auctionNotice });
 * const doc = generateDocument(); // The document (docx) with the chat messages.
 *
 * @author - &commat;nascjoao &lt;oi&commat;nasc.dev&gt;
 */
export default function useGenerateDocument({
    chatMessages,
    auctionNotice,
}: {
    chatMessages: MessageItem[];
    auctionNotice: AuctionNotice;
}) {
    const terms = useSenderTypeTerms(auctionNotice);

    function generateDocument() {
        const messages = parseMessagesToDoc({ messages: chatMessages, terms });
        const currentDate = new Date();

        const heading1 = new Paragraph({
            children: [
                new TextRun({
                    text: `Processo Licitatório Nº ${auctionNotice.auctionNumber}`,
                    font: 'Arial',
                }),
            ],
            spacing: {
                after: 200,
            },
            heading: HeadingLevel.HEADING_1,
        });

        const heading2 = new Paragraph({
            children: [
                new TextRun({
                    text: 'Chat da Sala de Disputa',
                    font: 'Arial',
                }),
            ],
            spacing: {
                after: 200,
            },
            heading: HeadingLevel.HEADING_2,
        });

        const exportedAt = new Paragraph({
            children: [
                new TextRun({
                    text: `Exportado em ${moment(currentDate).format('L')} às ${moment(
                        currentDate
                    ).format('HH:mm:ss')}`,
                    font: 'Arial',
                }),
            ],
            spacing: {
                after: 600,
            },
        });

        const doc = new Document({
            sections: [
                {
                    children: [heading1, heading2, exportedAt, ...messages],
                },
            ],
        });

        return doc;
    }

    return generateDocument;
}
