import { useState, useEffect, useRef, FC, useCallback } from 'react';
import Wrapper from '../Common/wrapper';
import AuctionLots from '../AuctionLots';
import { LotListAreaProps } from './props';
import { AuctionNoticeLot, IAuctionNoticeLot } from '../../Interfaces/auctionNoticeLot';
import ApiService, { getService } from '../../services/api';
import { orderBy, chain, omit, debounce } from 'lodash';
import { StageLot } from '../../Interfaces/stageLot';
import { CONSTANTS } from '../../constants';
import { SocketEvents, SocketEvent } from '../../Interfaces/socketEvents';
import { Button, Pagination } from 'antd';
import Image from '../Common/Image';
import TinyQueue from 'tinyqueue';
import LotListAreaHeader from '../LotListAreaHeader';
import { Spin, SpinArea } from 'Components/Common/Spin';
import { LoadingArea } from './styles';
import { timeout } from 'helpers/timer';
import { isAuctioneer, isProvider } from '../../helpers/permissions';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../helpers/mobile';
import { ITracking } from 'Interfaces/tracking';
import { useLotsContext } from 'pages/Initial/select-lots.context';

export enum LotsTab {
    not_started = 'not_started',
    started = 'started',
    open_period_ended = 'open_period_ended',
    random_period_ended = 'random_period_ended',
    close_period_ended = 'close_period_ended',
    negotiation = 'negotiation',
    all = 'all',
}

export interface PaginationState {
    skip: number;
    limit: number;
    tab?: LotsTab;
}

const initialPaginationState: PaginationState = {
    skip: 0,
    limit: 10,
    tab: LotsTab.all,
};

const getStoragedFilters = () => {
    const filters = localStorage.getItem(CONSTANTS.storageMap.FILTERS);

    if (typeof filters !== 'string') {
        localStorage.removeItem(CONSTANTS.storageMap.FILTERS);
        return {};
    }

    const removeLocal = () => localStorage.removeItem(CONSTANTS.storageMap.FILTERS);

    try {
        const obj = JSON.parse(filters);
        if (obj && typeof obj === 'object' && obj !== null) {
            return JSON.parse(filters);
        }
        removeLocal();
    } catch (err) {
        removeLocal();
    }
};

const LotListArea: FC<LotListAreaProps> = ({
    authUser,
    auctionNotice,
    auctionNoticeLotSelected,
    socketConnection,
    onAuctionSelected,
    serverTimestamp,
    onSocketConnectionCreated,
    onUpdateSelectedLot,
    providerCodeCreated,
}) => {
    const LOT_LIMIT_PAGINATION = 10;

    const getInitialFilters = (): PaginationState => {
        const currentFilters = getStoragedFilters();

        return {
            skip: currentFilters?.skip ?? initialPaginationState.skip,
            limit: currentFilters?.limit ?? initialPaginationState.limit,
            tab: currentFilters?.tab ?? initialPaginationState.tab,
        };
    };

    const { t } = useTranslation();

    const [auctionNoticeLots, setAuctionNoticeLots] = useState<IAuctionNoticeLot>({});
    const [pagination, setPagination] = useState<PaginationState>(getInitialFilters());
    const [auctionNoticeLotsCount, setAuctionNoticeLotsCount] = useState<number>(0);

    const [loadingMore, setLoadingMore] = useState(true);

    const { setLotStageFiltered, setSelectedMode } = useLotsContext();

    const auctionNoticeLotsRef: any = useRef(null);
    auctionNoticeLotsRef.current = { auctionNoticeLots, setAuctionNoticeLots };

    const auctionNoticeLotsCountRef: any = useRef(null);
    auctionNoticeLotsCountRef.current = { auctionNoticeLotsCount, setAuctionNoticeLotsCount };

    const authUserRef: any = useRef(null);
    authUserRef.current = { authUser };

    let queueLock = false;
    const queueEvents: any = new TinyQueue([]);

    useEffect(() => {
        getAuctionNoticeLotList();
        createFiltersStorage(pagination);
        setLotStageFiltered(pagination.tab ?? LotsTab.all);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    const fetchLotsAgain = () => {
        setLotStageFiltered(LotsTab.all);
        setPagination((prevState) => ({
            ...prevState,
            tab: LotsTab.all,
            skip: prevState.limit * 0,
        }));
        setLoadingMore(true);
    };

    const createFiltersStorage = (pagination: PaginationState) => {
        try {
            localStorage.setItem(
                CONSTANTS.storageMap.FILTERS,
                JSON.stringify({
                    ...pagination,
                })
            );
        } catch (error) {
            console.error(error);
        }
    };

    const getAuctionNoticeLotList = async () => {
        const filters: { [key: string]: any } = {
            ...omit(pagination, 'tab'),
        };

        filters.stage = pagination.tab;

        try {
            const response = await ApiService.getAuctionNoticeLots(auctionNotice?.id, filters);
            const { count, lots } = response;
            const auctionNoticeLots: IAuctionNoticeLot = transformAuctionNoticeLots(
                orderBy(lots, 'item', 'asc')
            );

            auctionNoticeLotsRef.current.setAuctionNoticeLots(auctionNoticeLots);
            onAuctionSelected(auctionNoticeLots[Object.keys(auctionNoticeLots)[0]]);
            setAuctionNoticeLotsCount(count);
            // inicia conexão com lotes atuais
            startSocket(lots);
            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
            console.error(error);
        }
    };

    const handleProviderAuctionCodeCreated = (data: { providerAuctionCode: number }) => {
        providerCodeCreated(data.providerAuctionCode);
        // @TODO: colocar restart do socket
    };

    const startSocket = async (auctionNoticeLots: AuctionNoticeLot[]) => {
        const { authUser } = authUserRef.current;

        const rooms = auctionNoticeLots.reduce(
            (acc, auction) => (acc = acc + auction.id + ','),
            ''
        );
        const aucioneerRooms = `auctioneer:${auctionNotice.id}:${authUser.userId}`;
        let providerRooms = `provider:${auctionNotice.id}:${authUser.providerId}`;

        if (isProvider(authUser) && authUser.providerAuctionCode) {
            providerRooms += `,participate:${authUser.providerAuctionCode}:${auctionNotice.id}`;
        }
        let userRooms: string = '';

        if (isProvider(authUser)) {
            userRooms = `,${providerRooms}`;
        }

        if (isAuctioneer(authUser)) {
            userRooms = `,${aucioneerRooms}`;
        }
        const serviceDispute = getService();
        let socketUrl = `${CONSTANTS.API_URL}?serviceDispute=${serviceDispute}&rooms=${rooms}auction:${auctionNotice.id}`;

        if (userRooms) {
            socketUrl += userRooms;
        }
        onSocketConnectionCreated(socketUrl);
    };

    useEffect(() => {
        handleSocketEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const transformAuctionNoticeLots = (
        auctionNoticeLots: AuctionNoticeLot[]
    ): IAuctionNoticeLot => {
        return chain(auctionNoticeLots)
            .map((lot: AuctionNoticeLot) => ({
                ...lot,
                stage: lot.status || StageLot.unStarted,
            }))
            .keyBy('id')
            .mapValues()
            .value();
    };

    const updateLotsState = (lotsRef: any, lotsIds: number[], lotInBulkAction: boolean) => {
        return lotsIds.reduce((acc, lotId) => {
            const lot = lotsRef[lotId];
            if (!lot) return acc;
            return {
                ...acc,
                [lotId]: {
                    ...lot,
                    lotInBulkAction,
                },
            };
        }, {});
    };


    const handleBulkActionTracking = (tracking: ITracking) => {
        const { auctionNoticeLots, setAuctionNoticeLots } = auctionNoticeLotsRef.current;
        const lotsInProgress = updateLotsState(
            auctionNoticeLots,
            tracking.lotsIdInProgress,
            tracking.progress < tracking.total
        );

        const lotsCompleted = updateLotsState(auctionNoticeLots, tracking.lotsIdCompleted, false);
        const updatedLots = {
            ...auctionNoticeLots,
            ...lotsInProgress,
            ...lotsCompleted,
        };

        setAuctionNoticeLots(updatedLots);
    };

    const handleSocketEvents = () => {
        if (!socketConnection) return;

        socketConnection.on(SocketEvents.classifiedProposalLot, (event: any) =>
            receiveEvent({
                type: SocketEvents.classifiedProposalLot,
                message: event,
            })
        );

        socketConnection.on(SocketEvents.bulkActionTracking, (event: any) =>
            receiveEvent({
                type: SocketEvents.bulkActionTracking,
                message: event,
            })
        );

        socketConnection.on(SocketEvents.lotStarted, (event: any) =>
            receiveEvent({
                type: SocketEvents.lotStarted,
                message: event,
            })
        );

        socketConnection.on(SocketEvents.providerAuctionCodeCreated, (event: any) =>
            handleProviderAuctionCodeCreated(event)
        );
    };

    const handleStartLot = (lot: AuctionNoticeLot) => {
        const { auctionNoticeLots } = auctionNoticeLotsRef.current;

        // se o lote já está na tela ou a aba é negociação, ignora
        if (auctionNoticeLots?.[lot.id] || pagination.tab === LotsTab.negotiation) {
            return;
        }

        const newAuctionNoticeLots = {
            ...auctionNoticeLots,
            [lot.id]: {
                ...lot,
                stage: StageLot.started,
            },
        };

        setAuctionNoticeLots(newAuctionNoticeLots);

        //se está em alguma aba sem nenhum lote, seleciona o que primeiro que chegar
        if (Object.values(newAuctionNoticeLots).length === 1) {
            onAuctionSelected(newAuctionNoticeLots[lot.id]);
        }

        // inicia socket novamente para adicionar listener do lote que chegou
        timeout(() => startSocket(Object.values(newAuctionNoticeLots)), 0);
    };

    // TODO: Funcões que adicionam lote na lista devem respeitar o limite de 10
    const handleClassifiedProposalLot = (lot: AuctionNoticeLot, providerAuctionCode: number) => {
        const { auctionNoticeLots, setAuctionNoticeLots } = auctionNoticeLotsRef.current;
        const { authUser } = authUserRef.current;

        if (
            auctionNoticeLots?.[lot.id] ||
            pagination.tab === LotsTab.negotiation ||
            providerAuctionCode !== authUser.providerAuctionCode
        ) {
            return;
        }

        const newAuctionNoticeLots = {
            ...auctionNoticeLots,
            [lot.id]: {
                ...lot,
                stage: lot.status || StageLot.unStarted,
            },
        };

        setAuctionNoticeLots(newAuctionNoticeLots);

        const { auctionNoticeLotsCount, setAuctionNoticeLotsCount } =
            auctionNoticeLotsCountRef.current;

        setAuctionNoticeLotsCount(auctionNoticeLotsCount + 1);

        if (Object.values(newAuctionNoticeLots).length === 1) {
            onAuctionSelected(newAuctionNoticeLots[lot.id]);
        }

        timeout(() => startSocket(Object.values(newAuctionNoticeLots)), 0);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePaginate = useCallback(
        debounce((page: number) => {
            setPagination((prevState) => ({
                ...prevState,
                skip: prevState.limit * (page - 1),
            }));
        }, 400),
        []
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePaginateTabChanged = useCallback(
        // debounce par evitar spam na troca de abas
        debounce((tab: LotsTab) => {
            setPagination({
                ...initialPaginationState,
                tab,
            });
        }, 400),
        []
    );

    // remove lote da lista apenas nas abas != all
    const removeLotFromView = (lotId: number) => {
        if (pagination.tab === LotsTab.all) {
            return;
        }

        timeout(() => {
            const { auctionNoticeLots, setAuctionNoticeLots } = auctionNoticeLotsRef.current;

            if (auctionNoticeLots[lotId]) {
                delete auctionNoticeLots[lotId];
            }

            setAuctionNoticeLots({
                ...auctionNoticeLots,
            });
        }, 5000);
    };

    const receiveEvent = (message: SocketEvent) => {
        queueEvents.push(message);

        if (!queueLock) {
            queueLock = true;
            processEvent();
        }
    };

    const processEvent = async () => {
        try {
            while (queueEvents.length) {
                const event: SocketEvent = queueEvents.pop();
                const { type, message } = event;

                switch (type) {
                    case SocketEvents.bulkActionTracking:
                        return handleBulkActionTracking(message);
                    case SocketEvents.classifiedProposalLot:
                        const { lot, providerAuctionCode } = message;
                        return handleClassifiedProposalLot(lot, providerAuctionCode);

                    case SocketEvents.lotStarted:
                        return handleStartLot(message);
                }

                await new Promise((r) => timeout(r, 15));
            }
        } finally {
            queueLock = false;
        }
    };

    const getStyles = () => {
        if (isMobile()) {
            return {
                width: '100%',
            };
        }

        return {};
    };

    return (
        <Wrapper
            bgcolor='#efefef'
            flex
            maxHeight='100%'
            flexBox
            flexDirection='column'
            {...getStyles()}
        >
            <LotListAreaHeader
                handlePaginateTabChanged={(tab) => {
                    setLoadingMore(true);
                    setSelectedMode(undefined);
                    setLotStageFiltered(tab);
                    handlePaginateTabChanged(tab);
                }}
                auctionNotice={auctionNotice}
                pagination={pagination}
                auctionNoticeLots={auctionNoticeLotsRef.current.auctionNoticeLots}
                authUser={authUser}
                socketConnection={socketConnection}
            />
            <Wrapper maxHeight={`calc(100% - ${isProvider(authUser) ? '180px' : '160px'})`}>
                {loadingMore ? (
                    <LoadingArea
                        style={{
                            maxHeight: `calc(100% - ${isProvider(authUser) ? '180px' : '160px'})`,
                        }}
                    >
                        <Spin />
                    </LoadingArea>
                ) : auctionNoticeLotsCountRef.current.auctionNoticeLotsCount === 0 ? (
                    <>
                        {isProvider(authUser) && authUser.providerAuctionCode === undefined ? (
                            // se cair aqui, significa que o fornecedor não teve nenhuma proposta aprovada pelo pregoeiro
                            <>
                                <Wrapper textAlign='center'>
                                    {t('info.no-proposals.classified')}
                                </Wrapper>
                                <Wrapper margin='20px 0 0 0' fontWeight='600' textAlign='center'>
                                    {t('info.waiting-connected')}
                                </Wrapper>
                                <Wrapper flexBox margin='50px 0 0 0' justifyContent='center'>
                                    <Image
                                        src='../../assets/img/wait.svg'
                                        height='150px'
                                        width='200px'
                                    />
                                </Wrapper>
                            </>
                        ) : (
                            <Wrapper
                                flexBox
                                height='300px'
                                alignItems='center'
                                borderTop='1px solid #D6D6D6'
                                justifyContent='center'
                            >
                                <Wrapper>
                                    <Wrapper textAlign='center'>{t('info.no-lots')}</Wrapper>
                                    <Wrapper flexBox margin='50px 0 0 0' justifyContent='center'>
                                        <Image
                                            src='../../assets/img/empty_draw.svg'
                                            height='150px'
                                            width='200px'
                                        />
                                    </Wrapper>
                                </Wrapper>
                            </Wrapper>
                        )}
                    </>
                ) : socketConnection ? (
                    <AuctionLots
                        key={`auctionLots:${auctionNotice.id}`}
                        auctionNotice={auctionNotice}
                        authUser={authUser}
                        socketConnection={socketConnection}
                        onSelectAuctionLot={onAuctionSelected}
                        auctionNoticeLotSelected={auctionNoticeLotSelected}
                        auctionNoticeLots={auctionNoticeLotsRef.current.auctionNoticeLots}
                        serverTimestamp={serverTimestamp}
                        onUpdateSelectedLot={onUpdateSelectedLot}
                        removeLotFromView={removeLotFromView}
                        fetchLotsAgain={fetchLotsAgain}
                    />
                ) : (
                    <SpinArea>
                        <Spin />
                    </SpinArea>
                )}
            </Wrapper>
            {auctionNoticeLotsCount > LOT_LIMIT_PAGINATION && !loadingMore && (
                <Wrapper margin='15px 0 0 0 ' flexBox justifyContent='center' alignItems='center'>
                    <Pagination
                        showSizeChanger={false}
                        defaultCurrent={pagination.skip / 10 + 1 ?? 1}
                        total={auctionNoticeLotsCount}
                        onChange={(page) => {
                            setLoadingMore(true);
                            handlePaginate(page);
                        }}
                        locale={{
                            jump_to: t('info.jump-to'),
                            page: '',
                        }}
                        showQuickJumper={
                            auctionNoticeLotsCount / LOT_LIMIT_PAGINATION >= 10
                                ? {
                                      goButton: (
                                          <Button
                                              style={{
                                                  margin: '0 0 0 8px',
                                                  background: '#var(--platform-secondary-color)',
                                                  color: '#fff',
                                              }}
                                          >
                                              {t('info.go')}
                                          </Button>
                                      ),
                                  }
                                : undefined
                        }
                    />
                </Wrapper>
            )}
        </Wrapper>
    );
};

export default LotListArea;
