import { getCompetentAuthorityName } from 'helpers/auction-rules';
import { AuctionNotice } from 'Interfaces/auctionNotice';
import { SenderType } from 'Interfaces/senderType';
import { useTranslation } from 'react-i18next';

type SenderTypeTerms = {
    [SenderType.auction]: string;
    [SenderType.system]: string;
    [SenderType.provider]: string;
};

/**
 * @name useSenderTypeTerms
 * @summary - This hook returns an object with the terms for each message sender type.
 *
 * @description - This hook is used to get the terms for each message sender type. These terms are used to display the sender type in the chat header.
 * They are translated using the translation hook.
 * The terms are:
 * - Auctioneer: The name of the competent authority. In portuguese, it is "leiloeiro" or "pregoeiro".
 * - System: The term "system". In portuguese, it is "sistema".
 * - Provider: The term "provider". In portuguese, it is "fornecedor".
 *
 * @example
 * // You can use the output as a dictionary to get the term for each sender type.
 * const terms = useSenderTypeTerms(auctionNotice);
 * const auctioneerTerm = terms[SenderType.auction]; // "Pregoeiro(a)"
 * const systemTerm = terms[SenderType.system]; // "Sistema"
 * const providerTerm = terms[SenderType.provider]; // "Fornecedor"
 *
 * @author - &commat;nascjoao &lt;oi&commat;nasc.dev&gt;
 */
export default function useSenderTypeTerms(auctionNotice: AuctionNotice): SenderTypeTerms {
    const { t } = useTranslation();

    const terms = {
        [SenderType.auction]: t('term.auctioneer', {
            name: getCompetentAuthorityName(auctionNotice),
        }),
        [SenderType.system]: t('term.system'),
        [SenderType.provider]: t('term.provider'),
    };

    return terms;
}
