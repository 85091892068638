import { Checkbox, Tooltip } from "antd";
import Wrapper from "Components/Common/wrapper";
import { useLotsContext } from "pages/Initial/select-lots.context";
import { FC } from "react";

interface BulkActionCheckboxViewProps {
    tooltipTitle: string;
    lotId: number;
    onSelectLotToAction: (event: any) => void;
    disabled: boolean;
    selected: boolean;
}

const BulkActionCheckboxView: FC<BulkActionCheckboxViewProps> = ({
    tooltipTitle,
    lotId,
    onSelectLotToAction,
    disabled,
    selected,
}) => {
    const { selectedLots, selectedMode } = useLotsContext();

    if (!selectedMode) return <></>;

    return (
        <Wrapper
            style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '20px',
                height: '100%',
                zIndex: '1',
                right: '0',
                display: 'flex',
                backgroundColor: selected ? '#FFF' : '#f5f5f5',
            }}
            onClick={(ev) => ev.stopPropagation()}
        >
            <Tooltip title={tooltipTitle}>
                <Checkbox
                    disabled={disabled}
                    id={`checkbox-lot:${ disabled ? null : lotId}`}
                    checked={ !!selectedLots.find((id) => id === lotId)}
                    onChange={onSelectLotToAction}
                    style={{
                        position: 'absolute',
                        top: '32px',
                        left: '12px',
                        width: '100%',
                        height: '100%',
                        zIndex: '1',
                    }}
                />
            </Tooltip>
        </Wrapper>
    );
}

export default BulkActionCheckboxView;